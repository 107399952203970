// @flow

import React from "react";
import { AssignmentLayout, Layout, withPrivateRoute } from "@containers";
import { Redirect } from "@components/Shared";
import { REDIRECTIONS } from "@constants";

/**
 * Afgewerkte opdrachten
 */
const FinishedAssignments = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Afgewerkte opdrachten">
            <div>Afgewerkte opdrachten</div>
            <Redirect data={REDIRECTIONS.DUMMY_ASSIGNMENT} />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(FinishedAssignments);
